import React, { useMemo } from 'react';
import _isEmpty from 'lodash-es/isEmpty';
import classnames from 'classnames';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useTracking from 'components/Globals/Analytics';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester/TrackingTester';

import { useTranslation } from 'src/i18n';
import { TP } from 'constants/index';

import classes from './SelectDropdown.module.scss';

const SelectDropdown = ({
  label,
  name,
  value,
  options,
  disabled,
  onChange,
  onOpen,
  onClose,
  isLoading,
  styles,
  hasAllOption = false,
  hideBorder = false,
  autoWidth = false,
  size = 'medium',
  children,
  renderValue,
  isMultiple = false,
  isOpen,
  fullWidth = false,
  placeholder,
  trackingData,
  skipTracking,
  shrink = false,
  defaultValue,
  disablePortal,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const id = `select_${name}`;
  const track = useTracking();
  const trackingClasses = useTrackingClickStyles(trackingData, skipTracking);

  const handlerSelectChange = event => {
    event.stopPropagation();
    if (onChange) {
      onChange(event, event.target);
    }

    if (!_isEmpty(trackingData)) {
      track.click({ ...trackingData, meta: { ...trackingData?.meta, value: event.target?.value } });
    }
  };

  const menuOptions = useMemo(() => {
    if (!hasAllOption) {
      return options;
    }

    return [
      {
        id: 0,
        value: 0,
        label: t(`${TP}.FN_FILTER_ALL`),
        type: '',
      },
    ].concat(options || []);
  }, [options, hasAllOption, t]);

  const selectedValue = useMemo(() => {
    if (value) {
      return value;
    }

    return hasAllOption ? 0 : '';
  }, [value, hasAllOption]);

  const renderOption = (elements, optionsList) => {
    if (elements && typeof elements === 'function') {
      return elements({ options: optionsList });
    }

    return optionsList?.map(option => (
      <MenuItem key={option?.value} value={option?.value} className={classes.menuItem}>
        {option?.label}
      </MenuItem>
    ));
  };

  return (
    <FormControl
      className={classnames(classes.control, trackingClasses, {
        [styles?.formControl]: !!styles?.formControl,
        [classes?.hideBorder]: !!hideBorder,
      })}
      fullWidth={fullWidth}
      size={size}
    >
      <InputLabel
        className={classnames(classes.label, {
          [styles?.label]: !!styles?.label,
        })}
        id={id}
        {...(shrink ? { shrink: true } : {})}
      >
        {label}
      </InputLabel>
      <Select
        name={name}
        onClick={() => track.click(trackingData)}
        label={label}
        className={classnames({
          [classes.select]: !autoWidth,
          [styles?.select]: !!styles?.select,
        })}
        open={isOpen}
        defaultValue={defaultValue}
        {...(renderValue ? { renderValue } : {})}
        value={selectedValue}
        labelId={id}
        disabled={disabled}
        onChange={handlerSelectChange}
        onOpen={onOpen}
        onClose={onClose}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          disablePortal,
          classes: { paper: isLoading ? classes.paper__isLoading : classes.paper },
        }}
        autoWidth={autoWidth}
        multiple={isMultiple}
        {...(placeholder ? { placeholder } : {})}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {renderOption(children, menuOptions)}
      </Select>
    </FormControl>
  );
};

export default SelectDropdown;

import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SeparatorList, { SEPARATOR_TYPES } from 'components/uiLibrary/SeparatorList';
import EntityName from 'components/Globals/EntityName';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { getProductionCredits } from 'utils/productions';
import { useTranslation } from 'src/i18n';
import { ENTITY_TYPE, TP, TRACK_SLUG_IMPRESSION } from 'constants/index';

import usePageContext from 'utils/hooks/usePageContext';
import classes from './CastCrew.module.scss';

const getTrackingDataForClick = (trackingData, item) => ({
  ...trackingData,
  entityType: item?.entityType,
  entityId: item?.entity?.id,
  entityName: item?.entity?.name,
});

const extractCredits = items =>
  items?.flatMap(({ data: innerData }) =>
    innerData?.map(({ entity, entityType: entityTypeOfCast }) => ({
      ...entity,
      entityType: entityTypeOfCast,
    })),
  );

const ExpandedSection = ({
  casts,
  instrumentalists,
  crews,
  conductors,
  directors,
  hasWorkDetails,
  handleOnClick,
  trackingData,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isTablet } = useDeviceTypeLayouts();
  const { navigate } = usePageContext();
  const getLinkProps = useCallback((entity, entityType) => navigate?.getLinkProps({ entity, entityType }), [navigate]);

  return (
    <div className={classes.expandedSection}>
      <div className={classnames(classes.expandedSection__crew, { [classes.crewSection]: !!hasWorkDetails })}>
        {isTablet && (
          <Typography size="12" weight="medium" italic>
            {t(`${TP}.FN_CREW_TITLE`)}
          </Typography>
        )}
        {directors?.data?.length > 0 && (
          <div className={classes.expandedSection__crew_details}>
            <Typography size="12" color="secondary" italic className={classes.roleType}>
              {t(`${TP}.FN_DIRECTOR`)}
            </Typography>
            <div className={classes.primaryCastCrew}>
              {directors?.data?.map(director => (
                <LinkButton
                  key={director?.entity?.id}
                  target="_blank"
                  variant="text"
                  isLink
                  {...getLinkProps(director?.entity, director?.entityType)}
                  styles={{ root: classes.name }}
                  onClick={handleOnClick}
                  trackingData={getTrackingDataForClick(trackingData, director)}
                >
                  <EntityName
                    entityType={director?.entityType}
                    entity={{ id: director?.entity?.id, name: director?.entity?.name }}
                    trackingData={trackingData}
                    isRaw
                  />
                  {director?.isCover && <span className={classes.coverRole}>({t(`${TP}.FN_COVER`)})</span>}
                </LinkButton>
              ))}
            </div>
          </div>
        )}
        {conductors?.data?.length > 0 && (
          <div className={classes.expandedSection__crew_details}>
            <Typography size="12" color="secondary" italic className={classes.roleType}>
              {t(`${TP}.m_CONDUCTOR`)}
            </Typography>
            <div className={classes.primaryCastCrew}>
              {conductors?.data?.map(conductor => (
                <LinkButton
                  key={conductor?.entity?.id}
                  target="_blank"
                  variant="text"
                  isLink
                  {...getLinkProps(conductor?.entity, conductor?.entityType)}
                  styles={{ root: classes.name }}
                  onClick={handleOnClick}
                  trackingData={getTrackingDataForClick(trackingData, conductor)}
                >
                  <EntityName
                    entityType={conductor?.entityType}
                    entity={{ id: conductor?.entity?.id, name: conductor?.entity?.name }}
                    trackingData={trackingData}
                    isRaw
                  />
                  {conductor?.isCover && <span className={classes.coverRole}>({t(`${TP}.FN_COVER`)})</span>}
                </LinkButton>
              ))}
            </div>
          </div>
        )}
        {(conductors?.length > 0 || directors?.length > 0) && casts?.length > 0 && (
          <Typography size="12" color="secondary" className={classes.expandedSection_tilda}>
            ~
          </Typography>
        )}
        {crews?.map(crew => (
          <div className={classes.expandedSection__crew_details}>
            <Typography
              key={crew?.title}
              size="12"
              color={crew?.hasCurrentEntity ? 'primary' : 'secondary'}
              italic
              className={classes.roleType}
            >
              {crew?.title}
            </Typography>
            <div className={classes.list}>
              {crew?.data?.map(item => (
                <LinkButton
                  key={item?.id}
                  target="_blank"
                  variant="text"
                  isLink
                  {...getLinkProps(item?.entity, item?.entityType)}
                  styles={{
                    root: classnames(classes.name, {
                      [classes.name__isBold]: item?.isCurrentEntity,
                    }),
                  }}
                  onClick={handleOnClick}
                  trackingData={getTrackingDataForClick(trackingData, item)}
                >
                  <EntityName
                    entityType={item?.entityType}
                    entity={{ id: item?.entity?.id, name: item?.entity?.name }}
                    trackingData={trackingData}
                    isRaw
                  />
                  {item?.isCover && <span className={classes.coverRole}>({t(`${TP}.FN_COVER`)})</span>}
                </LinkButton>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={classnames(classes.expandedSection__cast, { [classes.castSection]: !!hasWorkDetails })}>
        {isTablet && (
          <Typography size="12" weight="medium" italic>
            {t(`${TP}.FN_CAST`)}
          </Typography>
        )}
        {casts?.map((cast, i) => (
          <div className={classes.expandedSection__cast_details} key={cast?.title}>
            <Typography
              key={i}
              size="12"
              color={cast?.hasCurrentEntity ? 'primary' : 'secondary'}
              italic
              className={classes.roleType}
            >
              {cast?.title}
            </Typography>
            <div className={classes.list}>
              {cast?.data?.map(item => (
                <LinkButton
                  target="_blank"
                  variant="text"
                  isLink
                  {...getLinkProps(item?.entity, item?.entityType)}
                  styles={{
                    root: classnames(classes.name, {
                      [classes.name__isBold]: item?.isCurrentEntity,
                    }),
                  }}
                  key={item?.id}
                  onClick={handleOnClick}
                  trackingData={getTrackingDataForClick(trackingData, item)}
                >
                  <EntityName
                    entityType={item?.entityType}
                    entity={{ id: item?.entity?.id, name: item?.entity?.name }}
                    trackingData={trackingData}
                    isRaw
                  />
                  {item?.isCover && <span className={classes.coverRole}>({t(`${TP}.FN_COVER`)})</span>}
                </LinkButton>
              ))}
            </div>
          </div>
        ))}
        {instrumentalists?.map((instrumentalist, i) => (
          <div className={classes.expandedSection__cast_details} key={instrumentalist?.title}>
            <Typography
              key={i}
              size="12"
              color={instrumentalist?.hasCurrentEntity ? 'primary' : 'secondary'}
              italic
              className={classes.roleType}
            >
              {instrumentalist?.title}
            </Typography>
            <div className={classes.list}>
              {instrumentalist?.data?.map(item => (
                <LinkButton
                  target="_blank"
                  variant="text"
                  isLink
                  {...getLinkProps(item?.entity, item?.entityType)}
                  styles={{
                    root: classnames(classes.name, {
                      [classes.name__isBold]: item?.isCurrentEntity,
                    }),
                  }}
                  key={item?.id}
                  onClick={handleOnClick}
                  trackingData={getTrackingDataForClick(trackingData, item)}
                >
                  <EntityName
                    entityType={item?.entityType}
                    entity={{ id: item?.entity?.id, name: item?.entity?.name }}
                    trackingData={trackingData}
                    isRaw
                  />
                  {item?.isCover && <span className={classes.coverRole}>({t(`${TP}.FN_COVER`)})</span>}
                </LinkButton>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CastCrew = ({ isExpanded = false, production, hasWorkDetails, isGraph, profile, entityType, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();

  // TODO: ALONG WITH TICKET FB-22466, ASK BACKEND TO SEND ENTITY TYPE IDENTIFIER IN PROFILE
  const { directors, conductors, cast, crew, instrumentalists } = getProductionCredits(production, {
    entityId: profile?.id,
    entityType: entityType || ENTITY_TYPE.ARTIST,
  });

  const isProductionArchived = production?.isArchived;

  const handleOnClick = e => {
    e.stopPropagation();
  };
  const { creditLabel, credits } = useMemo(() => {
    const castNames = extractCredits(cast);
    const crewNames = extractCredits(crew);

    if (castNames?.length > 0) {
      return {
        creditLabel: `${TP}.FN_CAST`,
        credits: castNames,
      };
    }

    if (crewNames?.length > 0) {
      return {
        creditLabel: `${TP}.FN_CREW_TITLE`,
        credits: crewNames,
      };
    }

    return {};
  }, [cast, crew]);

  if (isExpanded) {
    return (
      <ExpandedSection
        casts={cast}
        instrumentalists={instrumentalists}
        crews={crew}
        conductors={conductors}
        directors={directors}
        hasWorkDetails={hasWorkDetails}
        handleOnClick={handleOnClick}
        profile={profile}
        trackingData={trackingData}
      />
    );
  }

  return (
    <div className={classes.collapsedSection}>
      <div className={classes.collapsedSection__director}>
        {directors?.data?.length > 0 && (
          <Typography size="12" className={classes.names} blur={isProductionArchived}>
            <Typography weight="bold" size="12">
              D:{' '}
            </Typography>
            <SeparatorList
              data={directors.data.map(props => (
                <>
                  <EntityName
                    key={props?.entity?.id}
                    {...props}
                    eventType={TRACK_SLUG_IMPRESSION(props.entityType)}
                    trackingData={trackingData}
                  />
                  {props?.isCover && <span className={classes.coverRole}>({t(`${TP}.FN_COVER`)})</span>}
                </>
              ))}
              separator={SEPARATOR_TYPES.COMMA}
            />
          </Typography>
        )}
      </div>
      <div className={classes.collapsedSection__conductor}>
        {conductors?.data.length > 0 && (
          <Typography size="12" className={classes.names} blur={isProductionArchived}>
            <Typography weight="bold" size="12">
              C:{' '}
            </Typography>
            <SeparatorList
              separator={SEPARATOR_TYPES.COMMA}
              data={conductors.data.map(props => (
                <>
                  <EntityName
                    key={props?.entity?.id}
                    {...props}
                    eventType={TRACK_SLUG_IMPRESSION(props.entityType)}
                    trackingData={trackingData}
                  />
                  {props?.isCover && <span className={classes.coverRole}>({t(`${TP}.FN_COVER`)})</span>}
                </>
              ))}
            />
          </Typography>
        )}
      </div>
      {isDesktop && credits?.length > 0 && (
        <div
          className={classnames(classes.collapsedSection__cast, {
            [classes.graphCast]: !!isGraph,
          })}
        >
          <Typography size="12" truncate blur={isProductionArchived}>
            <Typography weight="bold" size="12">
              {t(creditLabel)}:{' '}
            </Typography>

            <SeparatorList
              data={credits.map(credit => (
                <>
                  <EntityName
                    key={credit?.id}
                    entityType={credit?.entityType}
                    trackingData={trackingData}
                    entity={credit}
                    isRaw
                  />
                  {credit?.isCover && <span className={classes.coverRole}>({t(`${TP}.FN_COVER`)})</span>}
                </>
              ))}
              separator={SEPARATOR_TYPES.COMMA}
            />
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CastCrew;
